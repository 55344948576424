.bulk-action__container {
  width: 100%;
}

.bulk-action-box {
  margin-top: -8px;
  display: grid;
  grid-template-columns: 1fr;
  min-width: 150px;
  height: 100%;
  justify-content: center;
  flex-direction: column;
  .MuiFormControl-root {
    min-width: 80% !important;
  }

  h3 {
    font-weight: 200;
    margin-right: 10px;
  }
}
.delete-confirm-box .confirm-date-form {
  display: flex;
  margin: 20px;
  padding: 24px;
  border: 1px solid #ebe6e6;
  h3 {
    text-align: center;
    padding: 13px;
  }
}
.confirm-with-form svg {
  color: grey !important;
}
