.form {
  display: grid;
  @include for-medium-screen-up {
    grid-template-columns: repeat(2, 1fr);
    padding: 10px;
    grid-template-areas:
      "pid pid"
      "status upload_csv"
      "name details"
      "controls controls";
  }

  &__name {
    @include for-medium-screen-up {
      grid-area: name;
    }
  }

  &__details {
    @include for-medium-screen-up {
      grid-area: details;
    }

    width: 80%;
    margin: auto;
    label {
      margin-top: 10px;
    }
    textarea {
      padding-top: 15px;
    }
  }

  &__pid {
    @include for-medium-screen-up {
      grid-area: pid;
    }

    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
    text-align: center;
    h3 {
      display: inline-block;
      margin-right: 20px;
    }
  }

  &__status {
    @include for-medium-screen-up {
      grid-area: status;
    }

    display: flex;
    h3 {
      margin-right: 10px;
      font-weight: 400;
    }
  }
  &__upload_csv{
    @include for-medium-screen-up {
      grid-area: upload_csv;
    }

    display: flex;
    h3 {
      margin-right: 10px;
      font-weight: 400;
    }
  }

  &__controls {
    @include for-medium-screen-up {
      grid-area: controls;
    }
    margin: auto;
    margin-top: var(--default-vertical-margin);

    display: flex;
    justify-content: center;
    align-items: center;

    button:first-child {
      margin-right: 10px;
    }
  }
}

.dark-grey-text {
  color: grey;
  font-weight: 400;
}

// autocomplete field
.MuiAutocomplete-endAdornment {
    top: auto !important;
}

// Advertiser api credential
.advertiser-cred-form {
  h4 {
    margin-bottom: 0;
    color: #5f5f5f;
  }

  span {
    display: flex;
  }
}

.form-text-area {
  display: grid;
  width: 100%;

  &_half-grid {
    grid-template-columns: 50%;
  }

  &_fifty-fifty {
    grid-template-columns: 50% 50%;
  }

  &_fifty-fifty_single {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
  
  &_mr {
    margin-right: 1.5rem;
  }

  &_mb {
    margin-bottom: 1rem;
  }

  textarea {
    padding-top: 1rem;
  }

  &_linegap {
    margin-top: 20px;
  }
}
