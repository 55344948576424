.targeting-history {
  width: 80vw;
  height: 80vh;
  position: relative;
  overflow: auto;

  h3,
  h4 {
    text-transform: uppercase;
  }
  &__content {
    position: absolute;
    width: 100%;
    display: flex;
    flex-direction: column;
  }
  &__header {
    color: black;
  }

  &__dateTime {
    display: flex;
    flex-direction: column;
    color: gray;
    margin-top: 10px;
    align-self: flex-end;
    span {
      padding: 10px;
    }
  }

  &__info-variables {
    margin: 0;
    padding: 0;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(80px, 1fr));
  }

  &--none {
    display: flex;
    margin: auto;
    justify-content: center;
    align-items: center;
    color: gray;
    font-size: 1rem;
  }
}

.history-rule-box {
  padding: 10px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  margin: 10px 0;
  &__contents {
    display: flex;
    flex-wrap: wrap;
  }

  &__provider-details {
    flex-grow: 3;
    display: flex;
    flex-direction: column;

    &__detail {
      display: grid;
      grid-template-columns: 20% 30% 50%;
      &:not(:only-child):not(:last-child) {
        border-bottom: 1px solid gray;
      }
    }
  }
}
