.color_red {
    background-color: #F27474 ;
}

.color_green {
    background-color: #D6F6D5 ;
}

.color_yellow {
    background-color: #FCEB8B;
}

.color_orange {
    background-color: orange ;
}

.color_black {
    background-color: black ;
}
