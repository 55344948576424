.filter-box__container {
  width: 100%;
  margin-top: -30px;
}

.filter-box {
  display: grid;
  grid-template-columns: 1fr;
  @include for-bigger-middle-screen {
    grid-template-columns: 80% 1fr;
  }
  align-items: center;
  &__controls {
    display: flex;
    flex-direction: column;
  }

  &__searchbar {
    h4 {
      font-weight: 200;
      margin-right: 10px;
    }
    display: flex;
    align-items: center;
    justify-content: flex-start;
    svg {
      fill: grey;
    }
    width: 100%;
  }
  &__date-picker {
    display: flex;
    justify-content: space-around;
    & > * {
      max-width: 120px;
    }
    @include for-bigger-middle-screen {
      flex-direction: row;
      justify-content: flex-start;
      & > * {
        max-width: 35%;
        width: 35%;
      }
    }
    gap: 1rem;
  }
  &__searchField {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    flex-direction: column;
    @include for-medium-screen-up {
      flex-direction: row;
    }
    @include for-bigger-middle-screen {
      min-width: 500px;
    }
  }
  &__button {
    margin-left: 2rem;
    display: flex;
    gap: 0.2rem;
    justify-content: flex-start;
    align-items: flex-end;
    button {
      height: 50px;
    }
    button:last-child {
      margin-top: 10px;
      background-color: green !important;
    }
    @include for-bigger-middle-screen {
      flex-direction: column;
      align-items: flex-end;
      button {
        width: 100% !important;
      }
    }
  }
  &__options {
    display: flex;
    align-items: stretch;
    flex-wrap: wrap;
    flex-direction: column;
    &-select {
      display: flex;
      gap: 1rem;
      flex-direction: column;
      margin-right: 20px;
      min-width: 250px;
      @include for-bigger-middle-screen {
        .MuiFormControl-root {
          min-width: 190px;
        }
        flex-direction: row;
      }
    }

    h4 {
      font-weight: 500;
      color: #949494;
      margin-right: 15px;
    }
    &-checkbox {
      color: #949494;
    }
    input {
      &:checked + svg path {
        fill: green !important;
      }
    }

    @include for-bigger-middle-screen {
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
    }
  }
}

.selected-country-list {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 10px 0;
  // display: grid;
  // grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  h4 {
    color: gray;
    font-weight: 300;
    margin-right: 20px;
  }
  &__chip {
    margin-right: 5px;
  }
}
