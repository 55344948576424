.advisor-table {
    width: 100%; 
    border-collapse: collapse; 
    border: 1px solid #c9c9c9; 
    text-align: left; 
    // padding: 8px;
    box-shadow: 0px 4px 10px 0px #c3c3c3;

    tbody {
        tr {
            &:first-child{
                background-color: var(--color-primary);
                color: #fff;
            }
        }
        th, td {
            padding: 7px 12px;
        }
        td {
            &:hover{
                background-color: var(--color-primary-opaque);
            }
        }
    }
}