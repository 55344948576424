.warning-box {
  display: flex;
  padding: var(--default-padding-medium);
  border-left: 2px solid var(--color-darkyellow);
  justify-content: space-evenly;
  align-items: flex-end;
  cursor: pointer;
  svg {
    color: var(--color-darkyellow);
  }
  .warning {
    &__header {
      h4 {
        font-size: 1.2rem;
        font-weight: 300;
        margin: 0;
      }
    }
    &__sub-header {
      margin-top: 10px;
      font-weight: 400;
      color: var(--color-gray);
      font-size: 1.2;
    }
  }

  @include for-medium-screen-up {
    .warning {
      &__header {
        h4 {
          font-size: 1.4rem;
          font-weight: 300;
          margin: 0;
        }
      }
      &__sub-header {
        margin-top: 10px;
        font-weight: 400;
        color: var(--color-gray);
        font-size: 1.3;
      }
    }
  }
}
