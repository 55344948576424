.info-box {
    display: grid;
    grid-template-columns: 10% 1fr;
    padding: var(--default-padding-medium);
    justify-content: space-between;
    align-items: center;
    border-left: 2px solid var(--color-primary);
    text-align: left;

    &__icon {
        font-size: 30px;
        margin-right: 5px;

        svg {
            font-size: inherit !important;
        }
    }

    &__header {
        text-transform: uppercase;

        h4 {
            font-size: 1.3rem;
            font-weight: 700;
            margin: 0;
            color: var(--color-primary);
        }
    }

    &__children {
        margin-top: 10px;
        font-weight: 400;
        color: var(--color-gray);
        font-size: 1.3;
    }

    @include for-medium-screen-up {
        &__icon {
            font-size: 50px;
        }
    }
}
