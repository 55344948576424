.content__header {
  display: flex;
  align-items: center;
  padding-bottom: 5px;
  gap: 1rem;
  flex-wrap: wrap;
  // border-bottom: 1px solid var(--color-border-gray);
  width: 100%;
  &__icon {
    font-size: 2rem;
  }
  @include for-medium-screen-up {
    margin-bottom: 5px;
    font-size: 1.1rem;
    &__icon {
      font-size: 2rem;
      margin-right: 10px;
    }
  }

  &__text {
    span {
      color: var(--color-gray);
    }
  }

  h2 {
    margin: 0;
    font-weight: 300;
    font-size: 1rem;
  }
}
