.api-details {
  margin: 20px;
  border: 1px solid grey;
  .header{
    display:flex;
    justify-content:space-between;
    padding:10px;
    background:#efefef;
  }
  h2{
    font-size: 20px;
    font-weight: 400;
  }
  ul {
    list-style: none;
    .subid{
      display: flex
    }
    li {
      margin-bottom: 10px;
      font-size: 14px;
      color: #828282;
      line-height: 22px;
      .skipped-uploaded-subId{
        background: whitesmoke;
        padding: 11px;
        margin: 10px;
      }
      span{
        font-size: 16px;
        margin: 0px 10px 0px 0px;
    font-weight: 500;
    color: #404040;
      }
    }
  }
}
