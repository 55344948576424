.system-logs {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-areas:
    "header"
    "options"
    "table";
  gap: 1em;

  @include for-medium-screen-up {
    grid-template-columns: 200px 1fr;
    grid-template-areas:
      "header header"
      "options table";
  }
  &__header {
    grid-area: header;
  }
  &__options {
    grid-area: options;
    display: grid;
    grid-template-columns: 40% 1fr;
    @include for-medium-screen-up {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
    }
    gap: 1em;
  }

  &__table {
    grid-area: table;
    display: flex;
    gap: 0.5em;
  }

  &__files {
    height: 200px;
  }
}

.system-logs__filter-box {
  display: grid;
  grid-template-columns: 1fr;
  gap: 1rem;
  flex-direction: column;
  &__buttons {
    button:last-child {
      background-color: green !important;
      width: 100%;
    }
  }
}

.system-logs__files {
  text-align: center;
  text-transform: uppercase;
  &__list {
    overflow: auto;
    max-height: 200px;
    @include for-medium-screen-up {
      max-height: 300px;
    }
  }
  h4 {
    font-size: 1.3rem;
    font-weight: 100;
  }
}

.system-logs__table {
  display: flex;
  flex-direction: column;
  &:first-child {
    margin-bottom: var(--default-vertical-margin);
  }
}
