.infos {
  &__default-settings {
    display: grid;
    grid-gap: 1rem;
    grid-template-columns: repeat(auto-fit, minmax(300px, 700px));
  }
}
.dashboard-report {
  font-size: 1rem;
  .download-btn {
    width: 120px;
    margin-top: 20px;
    align-self: flex-start;
    background-color: hsl(202, 97, 51);
    &:hover {
      background-color: hsl(202, 97, 31);
      transform: scale(0.98);
    }
    margin-bottom: 10px;
  }
}

.dashboard-report__filters__action-buttons {
  display: flex;
  gap: var(--gap-small);
  flex-direction: row;

  button.download-btn:last-child {
    align-self: flex-end;
    margin-left: auto;
  }
}

.dashboard-report__filters__filter-selects {
  width: 100%;
  display: flex;
  gap: var(--gap-small);
  flex-direction: column;
  align-items: flex-start;
  @include for-bigger-middle-screen {
    display: grid;
    grid-template-columns: auto, auto;
    gap: var(--gap-small);
    align-items: self-start;
  }
}

.MuiChip-root {
  height: auto !important;

  .MuiChip-label {
    white-space: normal;
    padding-top: 7px;
    padding-bottom: 7px;
  }
}

.MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] {
  padding-right: 75px !important;
}


.dashboard-stats {
  @include for-medium-desktop-up {
    position: absolute;
    top: 50px;
    right: 100px;
    background-color: white;
  }

  @include for-desktop-up {
    position: absolute;
    top: 50px;
    right: 100px;
    background-color: white;
  }
}
