.advisors-container {
    display: flex;
    flex-direction: column;
    position: relative;

    &__header {
        display: flex;
        align-items: center;
        padding: 0 24px;
    }
}

.advisors-filter {
    &__root {
        width: 100%;
        margin-bottom: var(--default-vertical-margin);

        @include for-desktop-up {
            width: 80%;
        }
    }

    &__container {
        width: 100%;
    }

    &__selections {
        width: 100%;
        display: flex;
        gap: var(--gap-small);
        flex-direction: column;

        @include for-bigger-middle-screen {
            display: grid;
            // grid-template-columns: repeat(auto-fill, minmax(150px, 290px));
            grid-template-columns: 2fr 1fr 1fr 2fr;
            gap: var(--gap-medium);
            align-items: center;
        }

        @include for-big-desktop-up {
            display: grid;
            // grid-template-columns: repeat(auto-fill, minmax(150px, 290px));
            grid-template-columns: 2fr 1fr 1fr 1.2fr;
            gap: var(--gap-medium);
            align-items: center;
        }
    }

    &__actions {
        display: flex;
        gap: 10px;
    }
}

.confirm-box {
    display: flex;
    justify-content: space-around;
    flex-direction: column;
    align-items: center;
    flex-wrap: wrap;
    text-align: center;

    &__warning {
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: center;
    }

    svg {
        color: var(--color-darkyellow);
    }

    >* {
        margin-left: 5px;
    }

    &__buttons {
        margin-top: 10px;
        display: flex;

        button:first-child {
            margin-right: 10px;
        }
    }
}
