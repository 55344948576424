.targeting-form {
    &__publisher-section {
        display: flex;
        flex-direction: column;
        gap: var(--gap-small);
        border-radius: 8px;
        border: 1px solid #e0e0e0;
        padding: 15px;
        padding-top: 10px;

        @include for-medium-screen-up {
            padding-top: 20px;
            gap: var(--gap-medium);
        }

        &__top {
            display: grid;
            grid-template-columns: 1fr;
            gap: var(--gap-small);

            @include for-medium-screen-up {
                grid-template-columns: 1fr 1fr;
            }

            @include for-desktop-up {
                align-items: start;
                grid-template-columns: 1fr 15% 1fr 10%;
                gap: 5em;
            }
        }

        &__mid {
            display: grid;
            gap: var(--gap-small);

            @include for-medium-screen-up {
                grid-template-columns: 1fr 1fr;
                grid-template-areas:
                    "tag-options status"
                    "notes notes";
            }

            @include for-desktop-up {
                grid-template-columns: 30% 1fr 30%;
                grid-template-areas: "tag-options notes status";
            }
        }

        &__bottom {
            display: grid;

            @include for-medium-screen-up {
                grid-template-columns: 70% 30%;
            }
        }
    }

    //individual elements
    &__client-id {
        .MuiFormControl-root {
            min-width: 100%;
        }
    }

    &__publisher {
        .MuiFormControl-root {
            min-width: 100%;
        }
    }

    &__cus-domain {
        @include for-desktop-up {
            display: grid;
            grid-template-columns: 25% 1fr;
        }
    }

    &__active {
        display: flex;
        justify-content: flex-end;
    }

    &__tag-options {
        @include for-medium-screen-up {
            grid-area: tag-options;
        }
    }

    &__notes {
        display: flex;
        flex-direction: column;
        gap: var(--gap-small);

        @include for-medium-screen-up {
            grid-area: notes;
            flex-direction: row;
        }
    }

    &__status__section {
        display: flex;
        align-items: flex-end;

        >div {
            display: flex;
            justify-content: flex-end;
            align-items: flex-end;

            @include for-desktop-up {
                width: 250px;
            }

            > :nth-child(2) {
                max-height: 100%;
            }

            > :nth-child(2):hover {
                background-color: hsl(231, 48%, 48%);

                span {
                    color: white;
                }
            }
        }

        @include for-medium-screen-up {
            grid-area: status;
        }

        @include for-desktop-up {
            justify-content: flex-end;
            flex-direction: column;
        }

        .duplicate-btn {
            button {
                display: none;
                font-size: 0.8rem;
                max-width: 190px;
                max-height: 60px;

                span {
                    color: #fff;
                }
            }

            @include for-desktop-up {
                button {
                    display: none;
                    max-width: 100%;
                    max-height: 80px;
                    width: 250px;
                }
            }
        }
    }

    &__tag {
        display: flex;
        flex-direction: column;
        gap: var(--gap-small);

        &__link-row {
            display: grid;
            grid-template-columns: 1fr 20%;
        }
    }

    &__update-info {
        display: flex;
        flex-direction: column;
        gap: var(--gap-small);
        align-items: flex-end;
    }

    span {
        color: #949494;
    }

    .targeting-form__publisher-section__upper-mid {
        span {
            color: black;
        }

        .link__properties-container {
            margin-bottom: 25px;
        }

        .targeting-form__update-info {
            p {
                width: 100%;
                margin: 0;
                text-align: left;
                color: #949494;
            }
        }
    }
}

.target__section {
    display: none;
    // display: flex;
    align-items: center;
    justify-content: space-evenly;
    border: 1px solid rgb(122, 119, 119);
    padding: 10px;
    border-radius: 8px;

    @include for-medium-screen-up {
        width: 440px;
        flex-direction: row;
    }

    flex-direction: column;

    .radio-section {
        flex-direction: column;

        @include for-medium-screen-up {
            flex-direction: row !important;
        }

        justify-content: space-between;

        >*:last-child {
            margin-left: 20px;
        }

        .targeting-option {
            display: flex;
            align-items: center;
            position: relative;

            .MuiFormControlLabel-label {
                max-width: max-content;
                // margin-left: 15px;
            }

            >*:last-child {
                position: absolute;
                right: -5px;
                top: 11px;
            }
        }
    }
}

.footer-section {
    display: flex;
    justify-content: center;
    margin-top: 10px;

    button {
        margin-right: 10px;

        span {
            color: #fff;
        }
    }

    .save-publish {
        background-color: #4caf50;

        &:hover {
            background-color: #306537;
        }
    }
}

.default-url-block {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    margin-bottom: 20px;

    span {
        color: #949494;
    }

    &__fallback {
        input {
            min-width: 30rem;
        }
    }
}

.rule__section {
    border: 1px solid rgb(122, 119, 119);
    padding: 10px;
    border-radius: 8px;
    margin-bottom: 20px;
    margin-top: 20px;
    position: relative;
    padding-bottom: 40px;
    padding-top: 40px;
    padding-left: 20px;
    padding-right: 20px;
    display: flex;
    justify-content: center;
    align-items: center;

    &__legend {
        padding: 10px;
    }

    @include for-medium-screen-up {
        padding-top: 35px;

        .min-width-field {
            max-width: 100px;
        }
    }

    &__id {
        // display: flex;
        display: none;
        // margin-top: 30px;
        align-items: center;

        @include for-bigger-middle-screen {
            margin: 0;
            grid-area: id;
        }

        @include for-desktop-up {
            align-items: flex-start;

            .drag-drop-icon {
                margin-top: 20px;
                cursor: pointer;
            }
        }
    }

    svg {
        // fill: #333;
        margin-bottom: 10%;
    }

    &__action-section {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        padding: 0;

        @include for-bigger-middle-screen {
            grid-area: action;
        }

        button {
            max-height: 50px;
            align-self: center;
        }
    }

    &__closeBtn {
        position: absolute;
        top: -10px;
        right: 0;
    }

    &__linked-accountBtn {
        position: absolute;
        left: 0;
        bottom: 0;
        padding: 1rem;
    }
}

.target-rules-section {
    display: flex;
    flex-direction: column;

    &__labels {
        display: flex;
        flex-direction: column;
        margin: 10px 0;

        >*+* {
            margin-top: 5px;
        }

        color: gray;

        svg {
            margin-right: 5px;
        }
    }

    button {
        span {
            color: white;
        }
    }
}

.target-rules-section__labels__warning {

    &--weights,
    &--dailycap {
        color: red;
    }
}

//targetings listing page container
.targetings-container {
    display: flex;
    flex-direction: column;
    position: relative;

    &__header {
        display: flex;
        align-items: center;
        padding: 0 24px;
    }

    &__filter-box {
        margin-bottom: 10px;
        display: grid;
        grid-template-columns: 99%;
        gap: 0.5em;

        @include for-desktop-up {
            grid-template-columns: 80% 1fr;
        }

        justify-content: space-between;
        align-items: start;
        // button {
        //   width: 140px;
        // }
    }

    &__addBtn {
        right: 2%;
        // top:10%;
        position: absolute;
    }
}

.rule-archive-message {
    h2 {
        padding: 0;
        margin: 0;
    }

    p {
        color: var(--color-gray);
        display: flex;
        gap: 1rem;
        align-items: flex-start;
        margin: 0;
        padding: 10px;
        font-family: monospace;
    }
}

.linked-accounts-open-btn {
    color: var(--color-primary-2);
    padding: 6px 8px;
    transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
        box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
        border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    text-transform: uppercase;
    background-color: white;
    border-radius: 2px;
    border: 1px solid var(--color-primary);
    cursor: pointer;

    &:hover {
        border: 1px solid var(--color-primary);
        color: var(--color-primary);
    }
}

.linked-account-modal {
    padding: var(--default-padding-medium);

    h3 {
        color: var(--color-gray-2);
        text-transform: uppercase;
    }
}

.linked-account-box {
    width: 250px;
    overflow: "auto";
    max-height: 70vh;

    @include for-medium-screen-up {
        width: 500px;
    }

    h2 {
        font-weight: 500;
        color: var(--color-gray-2);
        letter-spacing: 0.2rem;
    }
}

.linked-account-box__id-box {
    border-width: 3px;

    &:hover {
        cursor: pointer;
        border-color: var(--color-primary-2) !important;

        transition: all 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);
    }

    &__header {
        color: var(--color-primary-2);
        font-size: 1.2rem;
        font-weight: 500;
        letter-spacing: 0.1rem;
    }

    &__id {
        letter-spacing: 0.1rem;
        color: var(--color-gray);

        >*+* {
            margin-left: 2px;

            &::before {
                content: ", ";
            }
        }
    }
}

.tag-stats {
    display: flex;
    justify-self: flex-end;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    color: var(--color-gray);
    font-size: 1.2rem;
    font-weight: 500;
    letter-spacing: 0.1rem;
    flex-wrap: wrap;
    margin-left: auto;

    svg {
        fill: var(--color-gray);
    }
}

.target__duplicate-links-msg {
    font-size: 1.2rem;
    font-weight: 500;
    letter-spacing: 0.1rem;
    margin-top: 1rem;
}

.target__duplicate-links-msg p {
    display: flex;
    align-items: center;
    gap: 1rem;
}

.target__duplicate-links-msg p span {
    color: var(--color-primary-2);
}

.target__duplicate-links-msg p span:not(:last-child)::after {
    content: ",";
}

.target-duplicate-advertiser-links-msg {
    div {
        cursor: pointer;
        border: 1px solid var(--color-gray);
        padding: 0.4rem;
        border-radius: 2px;
    }

    div:hover {
        border: 1px solid var(--color-primary-2);
        color: var(--color-primary);
    }
}

.rule__section__report {
    .rule__stat__section {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    p {
        color: #718787;
        font-size: 0.9rem;
        margin: 0;
        margin-bottom: 5px;
    }

    p:last-child {
        color: #6aa84f;
    }

    .error-icon {
        fill: #fd443d;
        margin-right: 3px;
        font-size: 1.2rem;
    }

    h5 {
        color: #fd443d;
        font-size: 0.9rem;
    }
}

.d-none {
    display: none !important;
}
