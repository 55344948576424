.DateRangePickerInput__withBorder {
  border: 1px solid lightgray;
  border-radius: 4px;
  padding: 2px;
  font-family: sans-serif;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  &:hover {
    border-color: gray;
  }
}

.DateRangePicker_picker {
  z-index: 3;
}
