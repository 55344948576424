.drawer {
  .MuiDrawer-paper {
    width: 260px;
  }
  width: 240;
  flex-shrink: 0;
}
.drawer__paper {
  width: 240;
}
.drawer__header {
  display: flex;
  align-items: center;
  padding: 0 8px;
  justify-content: flex-end;
}
