.publisher-apis {
  margin: 30px;
  padding: 30px;
  border: 1px solid #e9e9e9;
  .header {
    text-align: center;
    margin: 41px;
    font-size: 22px;
    text-decoration: underline;
  }
  .body-content {
    display: grid;
    row-gap: 20px;
    .content {
      display: flex;
      flex-wrap: wrap;
      .left {
        font-size: 18px;
        font-weight: 500;
        text-align: left;
        padding: 10px;
        flex-basis: 20%;
      }
      .right {
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: 77%;
        text-align: left;
        background: #f1f1f1;
        padding: 10px;
      }
    }
  }
}
