.publisher-links-manage{

    &__section{
        border: 1px solid #e0e0e0;
        border-radius: 8px;
        padding: 10px;
        margin-top:2rem;
        display: flex;
        flex-direction: column;

        &-top{
            display: flex;
            justify-content:space-around;
            align-items:center;
            &__input{
                .MuiFormControl-root{
                    min-width: 200px;
                }
            }
        }

        &-medium{
            .linkInfos{
                margin-top:10px;
                margin-bottom:10px;
                >* {
                    margin-bottom:10px;
                }
                .linkInfo{
                    border: 1px solid #e0e0e0;
                    border-radius: 8px;
                    padding: 10px;
                }
            }
        }
    }
}

//Individual Link Info Form
.linkInfo{
    position: relative;
    &__closeBtn{
        position: absolute;
        top:2%;
        right:2%;
    }
    &__top{
        display: flex;
        color:#a2a1a1;
        justify-content:space-around;
        align-items:flex-end;
        &-cusDomain{
            display: flex;
            flex-direction: column;
            justify-content:center;
            align-items:top;
        }
    }
    &__bottom{
        display: flex;
        .copy-icon {
            margin-bottom: 0px !important;
            padding: 0px !important;
            &:hover {
              padding: 4px !important;
              span {
                color:#3f51b5;
              }
            }
            span {
              color: #a2a1a1;
              svg {
                font-size: 1.5rem !important;
              }
            }
          }
        .MuiFormControl-root{
            max-width: 700px;
        }
    }
}

.related-connected-box {
  width: 250px;
  overflow: auto;
  max-height: 70vh;

  @include for-medium-screen-up {
    width: 500px;
  }

  &__heading {
    display: block;

    font-size: 1.5rem;
    // letter-spacing: 2;
    color: var(--color-primary);
    width: 100%;
    border-bottom: 1px solid var(--color-primary-opaque);
  }
}
.advertiser-link-group-rule-box {
    border-width: 2px;
    font-size: 1rem;
    letter-spacing: 0.1rem;
    margin-bottom: 1rem;

    &__nuetral {
        color: #333;
        display: flex;
    }

    &:hover {
        cursor: pointer;
        border-color: gray !important;

        transition: all 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);
    }
}

.connected-ad-box {
  border-width: 2px;
  border-color: green !important;
  font-size: 1rem;
  letter-spacing: 0.1rem;
  margin-bottom: 1rem;

  &__nuetral {
    color: #333;
    display: flex;
  }

  &:hover {
    cursor: pointer;
    border-color: gray !important;

    transition: all 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);
  }
}
