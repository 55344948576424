.logs-container {
  display: flex;
  flex-direction: column;
  gap: var(--gap-small);
  .download-btn {
    width: 120px;
    margin-top: 20px;
    align-self: flex-start;
    background-color: hsl(202, 97, 51);
    &:hover {
      background-color: hsl(202, 97, 31);
      transform: scale(0.98);
    }
    margin-bottom: 10px;
  }
}

.logs-filter-box {
  width: 100%;
  display: flex;
  flex-direction: column;
  @include for-bigger-middle-screen {
    display: grid;
    grid-template-columns: 80% 1fr;
  }
}

.logs__selections {
  width: 100%;
  display: flex;
  gap: var(--gap-small);
  flex-direction: column;
  @include for-bigger-middle-screen {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(150px, 300px));
    gap: var(--gap-small);
    align-items: center;
  }
}

.logs__action-buttons {
  display: flex;
  gap: var(--gap-small);
  flex-direction: row;
  @include for-medium-screen-up {
    flex-direction: column;
  }
  button:last-child {
    background-color: green !important;
  }
}

.log-tiles {
  &__container {
    display: flex;
    flex-direction: column;
    gap: var(--gap-small);
  }
  &__row {
    display: grid;
    width: 100%;
    grid-template-columns: 1fr;
    @include for-bigger-middle-screen {
      grid-template-columns: repeat(2, 1fr);
    }

    &__heading {
      text-align: center;
      font-size: 2rem;
    }
    align-items: center;

    &__info {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: var(--gap-small);
      width: 100%;
    }
  }
}
