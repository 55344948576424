.dashboard-report__filters {
  display: flex;
  flex-direction: column;
  width: 100%;

  &__filter-selects {
    display: flex;
    flex-direction: column;
    gap: var(--gap-small);
  }

  &__action-buttons {
    display: flex;
    gap: var(--gap-small);
  }
}
@include for-medium-screen-up {
  .dashboard-report__filters__filter-selects {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    align-items: center;
  }
}

.missing-subids-table {
  .missing-subids-heading {
    color: #6e6c6c;
    font-weight: 400;
    margin: 14px 0px;
    font-size: 15px;
  }
  .download-btn {
    width: 120px;
    margin-top: 20px;
    align-self: flex-start;
    background-color: #09a2fb;
    margin-bottom: 35px;
  }
}
