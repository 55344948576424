.spacing {
    &__top {
      margin-top: 20px;
    }
}

// custom push notification alert
.custom_push_notification {
    color: #fff !important;
    background-color: var(--color-primary) !important;
    
    .MuiAlert-icon {
        color: #fff !important;
    }
}
