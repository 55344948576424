.publishers-container {
    display: flex;
    flex-direction: column;
    position: relative;

    &__header {
        display: flex;
        align-items: center;
        padding: 0 24px;
    }

    &__filter-box {
        margin-bottom: 10px;
        display: grid;
        justify-content: space-between;
        grid-template-columns: 99%;
        align-items: start;
        gap: var(--gap-small);

        @include for-desktop-up {
            grid-template-columns: 80% 1fr;
        }

        // button {
        //   width: 140px;
        // }
    }

    &__buttons {
        justify-content: flex-end;
        align-self: flex-end;
        // top:10%;
        // position: absolute;
    }

    &__generateBtn {
        margin: 10px;
    }

    &__addBtn {
        right: 2%;
        position: absolute;
    }
}

.publisher-manage {
    // temp width while developing
    width: 70%;
    margin: 20px auto;

    fieldset {
        margin-top: 20px;
    }
}

.delete-confirm-box {
    display: flex;
    justify-content: space-around;
    flex-direction: column;
    align-items: center;
    flex-wrap: wrap;
    text-align: center;

    &__warning {
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: center;
    }

    svg {
        color: var(--color-darkyellow);
    }

    >* {
        margin-left: 5px;
    }

    &__buttons {
        margin-top: 10px;
        display: flex;

        button:first-child {
            margin-right: 10px;
        }
    }
}